import {Button, Calendar, CalendarCell, CalendarGrid, DateInput, DatePicker as ADatePicker, DateSegment, Dialog, Group, Heading, Popover, DialogTrigger} from 'react-aria-components';
import { CalendarState} from 'react-stately'

import {parseDate, parseAbsoluteToLocal, today, getLocalTimeZone, CalendarDate} from '@internationalized/date';
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon  } from '@heroicons/react/20/solid';
import clsx from 'clsx';

import { IconX } from '../icons';
import { useTranslation } from 'react-i18next';

export type DatePickerProps = {
    className: string;
    name: string;
    value: string;
    onChange: (e: {target: {value: string, id: string}}) => void;
    disabled?: boolean;
}

// Bundle increase from 815kB to 880kB...
export function DatePicker({className, name, value, onChange, disabled = false, ...props}) {
    const {t} = useTranslation()

    const emptyDate = {target: {value: undefined, id: name}, id: name}

    function setDate(state: CalendarState, date: CalendarDate) {
        state.selectDate(date)
        state.setFocusedDate(date)
    }

    return (
        <ADatePicker 
            aria-label={name} 
            value={value ? parseDate(value) : null} 
            onChange={v => onChange({target: {value: v?.toString(), id: name}}) }
            isDisabled={disabled}
            id={name}
            shouldCloseOnSelect={false}
            validationBehavior="native"
            {...{...props, name}}
        >{({state: {realtimeValidation}}: {state: {realtimeValidation: {isInvalid: boolean, validationErrors: string[]}}}) => {
            return (
                <DialogTrigger>
                    {/* 'Group' removed as it lead to strange behavior on Safari and Explorer (possibly also Chrome) */}
                    <div
                        title={realtimeValidation?.validationErrors?.[0]}
                        className={clsx('flex flex-row gap-1.5 items-center form-input select-none', realtimeValidation.isInvalid && 'border-red-400')}
                    >
                        <DateInput className='flex flex-row min-w-[7rem] select-none' >
                            {(segment) =>
                                <DateSegment segment={segment}
                                    className={clsx(
                                        'inline',
                                        'focus:ring-0 focus:outline-none focus:caret-transparent text-right tabular-nums',
                                        realtimeValidation.isInvalid ? 'focus:bg-red-200' : 'focus:bg-pcx-600 focus:text-white',
                                        segment.isPlaceholder && 'font-mono mx-1',
                                        segment.isEditable && 'px-2',
                                        //segment.type === 'year',
                                        //segment.type === 'month', 
                                        //segment.type === 'day',
                                    )} />}
                        </DateInput>
                        <Button><CalendarDaysIcon className='h-5 w-5 text-pcx-700' /></Button>
                        {/*<ClearDateButton {...{onChange, name}} />*/}
                        <button
                            // Don't inherit default Button behavior from DatePicker.
                            type="button"
                            className="cursor-pointer text-slate-600 hover:text-pcx-600"
                            aria-label="Clear"
                            onClick={() => onChange(emptyDate)}>
                            <IconX />
                        </button>
                    </div>
                    <Popover crossOffset={-72} className='bg-white rounded-md shadow border border-pcx-400 p-2 mt-2'>
                        <Dialog>
                            <Calendar className='text-sm'>{({ state }) => {
                                return <>
                                    <header className='flex flex-row gap-2 w-full justify-between'>
                                        <Button slot="previous" className="disabled:text-gray-400"><ChevronLeftIcon className='h-5 w-5' /></Button>
                                        <Heading />
                                        <Button slot="next"><ChevronRightIcon className='h-5 w-5' /></Button>
                                    </header>
                                    <div className='py-2 space-x-1 flex flex-row'>
                                        <button type="button" className='btn-secondary grow py-0.5' onClick={() => setDate(state, today(getLocalTimeZone()))}>{t('today')}</button>
                                        <button type="button" className='btn-secondary grow py-0.5' onClick={() => setDate(state, (state.value ?? today(getLocalTimeZone())).add({ months: 3 }))}>+3 {t('months')}</button>
                                    </div>
                                    <CalendarGrid>
                                        {(date) =>
                                            <CalendarCell date={date}
                                                className={({ isSelected, isOutsideVisibleRange, }) =>
                                                    clsx(
                                                        'tabular-nums m-0.5 p-1 text-right rounded ',
                                                        isOutsideVisibleRange ? 'text-gray-400' : 'hover:bg-pcx-200',
                                                        isSelected && 'bg-pcx-400 text-white'
                                                    )} />}
                                    </CalendarGrid>
                                </>
                            }}</Calendar>
                        </Dialog>
                    </Popover>
                </DialogTrigger>
            )
            }}</ADatePicker>
    )
}


// value is in UTC
export type DateTimePickerProps = {
    className?: string;
    name: string;
    value: string;
    onChange: (e: any) => void;
    disabled?: boolean;
}

export function DateTimePicker({className = '', value, onChange, name, ...props}) {
    //console.log({value})
    return (
        <ADatePicker 
            aria-label={name} 
            value={value ? parseAbsoluteToLocal(value) : null} 
            onChange={v => {
                //console.log({v, s: v?.toString()})
                onChange({target: {value: v?.toAbsoluteString(), id: name}, id: name}) 
            }}
            //isDisabled={disabled}
            id={name}
            hourCycle={24}
            hideTimeZone={true}
            shouldForceLeadingZeros={true}
            granularity='second'
            {...{...props, name}}
        >
            <Group className={clsx('flex flex-row gap-1.5 items-center form-input', className)}>
                <DateInput className='flex flex-row gap-0.5 tabular-nums' >
                    {(segment) =>
                        <DateSegment segment={segment}
                            className={clsx(
                                'focus:ring-0 focus:outline-none text-right',
                                segment.isPlaceholder ? 'font-mono' : 'tabular-nums',
                                'focus:bg-pcx-600 focus:text-white',
                                segment.isEditable && 'px-2',
                            )} />}
                </DateInput>
            </Group>
        </ADatePicker>
    )
}