import { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { CloudArrowDownIcon, PencilSquareIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { Outlet, useNavigate, useParams } from "react-router";
import { Formik, Form, Field } from "formik";
import clsx from "clsx";
import _ from 'lodash'

import EmptyState from "../components/EmptyState";
import { ClaimScopeManager } from "./ClaimScope";
import { DatePicker } from "../components/input/DatePicker";
import { useRoles } from "../user/Auth";
import Modal from "../components/Modal";
import { extractContent } from "../data"
import { DeleteButton, isoDateString } from "../components/edit-table"
import Editor from '../components/Editor'
import { useBackend } from "../BackendProvider";
import { useMessages } from "../Messages";
import { IconEdit, IconPlus } from "../components/icons";
import { Claim, useClaims } from "./ClaimsProvider";
import { usePatents } from "../patents/PatentsProvider";
import { useLocalState } from "../settings/localStorage";


export default function Claims() {
    return <ClaimsManager />
}

function ClaimsManager() {
    const { t } = useTranslation()
    const { currentClaims, memberClaims, familyClaims } = useClaims()
    const { hasClaimScopes, isEditUser } = useRoles()

    const [onlyShowIndependent, setOnlyShowIndependent] = useLocalState("claims-manager-only-show-independent", false)

    if (memberClaims?.length > 0)
        return (
            <div className={clsx(hasClaimScopes ? "max-w-7xl" : "max-w-3xl", "md:min-w-lg")} id="claims-manager">
                <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 pb-2 max-lg:items-end">
                    <h3 className="grow max-lg:self-start">{t('claims-manager')}</h3>
                    {currentClaims?.length > 0 && <label className="inline-flex gap-1 items-center text-slate-500">
                        <input
                            type="checkbox" className="form-checkbox focus:ring-0"
                            checked={onlyShowIndependent}
                            onChange={e => setOnlyShowIndependent(e.target.checked)} />
                        <div className="text-sm">{t('show-only-independent')}</div>
                    </label>}
                    <ClaimsDropdown />
                </div>
                <div className={clsx("grid grid-cols-1 gap-x-2 py-2", hasClaimScopes && "md:grid-cols-[2fr_1fr]")}>
                    {/* HEADER */}
                    <div className="flex md:items-center flex-col md:flex-row py-1 gap-4 mb-4">
                        <h4 className="sm:block grow">
                            {t('claims')}
                        </h4>
                        {isEditUser && <div className="flex flex-row gap-2 max-md:self-end text-sm">
                            <Link to="import" className="btn-secondary py-0.5">{t('load-from-epo')}</Link>
                            <Link to="add" className="btn-secondary py-0.5">{t('add-claim')}</Link>
                        </div>}
                    </div>
                    {hasClaimScopes && <h4 className="pl-4 hidden sm:block">{t('subfamily')}</h4>}
                    {/* CLAIMS */}
                    {currentClaims
                        .filter(({ claimType }) => !onlyShowIndependent || claimType === "independent-claim")
                        .map(claim => {
                            //const claimScope = (claim.claimScopeId && (scopesById[claim.claimScopeId] ?? [undefined])[0]) || undefined
                            return (
                                <Fragment key={claim.claimId} >
                                    <div key={"claim-" + claim.claimId}>
                                        <ClaimManager {...{ claim }} />
                                    </div>
                                    {hasClaimScopes &&
                                        <div className="max-md:border-b-2 md:border-l-2 pl-2 border-pc-200" key={"claim-scope-" + claim.claimId}>
                                            <ClaimScopeManager {...{ claim }} />
                                        </div>}
                                </Fragment>
                            )
                        })}
                </div>
                <Outlet />
            </div>
        )
    return (
        <div className={clsx("pt-0 pb-8", hasClaimScopes ? "max-w-7xl" : "max-w-[52rem]")}>
            <EmptyState title={t('claims-manager')} text={t("empty-claims-text")} items={noClaimsActions(t, familyClaims?.length > 0).filter(c => c)} />
            <Outlet />
        </div>
    )
}

const noClaimsActions = (t, hasFamilyClaims) => [
    {
        title: t("import-claims-from-epo"),
        description: t("import-claims-from-epo-desc"),
        icon: CloudArrowDownIcon,
        background: 'bg-pcx-800',
        to: "import",
    },
    hasFamilyClaims && {
        title: t("copy-claims"),
        description: t("copy-claims-desc"),
        icon: DocumentDuplicateIcon,
        background: 'bg-pcx-500',
        to: "version/add?copy=true",
    },
    {
        title: t("manually-add-claim"),
        description: t("manually-add-claim-desc"),
        icon: PencilSquareIcon,
        background: 'bg-pcx-300',
        to: "add",
    },
]


function ClaimsDropdown() {
    const { t } = useTranslation()
    const { isEditUser } = useRoles()
    const { claimVersion, availableVersions, setClaimVersion } = useClaims()

    const itemClassName = clsx(
        'hover:bg-pcx-600 hover:text-white text-pcx-500',
        'group flex whitespace-nowrap w-full items-center rounded-md px-2 py-2'
    )

    return (
        <Menu as="div" className="relative inline-block text-left text-sm">
            <div>
                <Menu.Button className={"btn-secondary inline-flex items-center gap-1 py-0.5 pl-2 pr-1"}>
                    <div> {`${t('version')}: ${claimVersion.version} (${claimVersion.versionDate})`}</div>
                    <ChevronDownIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-1 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {isEditUser && <Menu.Item>
                            <Link to="version/edit"
                                className={itemClassName}
                            >
                                <IconEdit
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                /> {t('edit')}
                            </Link>
                        </Menu.Item>}
                        {isEditUser && <Menu.Item>
                            <Link to="version/add"
                                className={itemClassName}
                            >
                                <IconPlus
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                /> {t('add-version')}
                            </Link>
                        </Menu.Item>}
                        {availableVersions.map(({ version, versionDate }) =>
                            <Menu.Item key={version}>
                                <button
                                    onClick={() => setClaimVersion({ version, versionDate })}
                                    className={itemClassName}
                                >
                                    <span className="pl-7">{`${t('version')} ${version} (${versionDate})`}</span>
                                </button>
                            </Menu.Item>
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

function ClaimManager({ claim }: { claim: Claim}) {
    const { isEditUser } = useRoles()
    const { entityOperation } = useBackend()

    return (
        <div className={clsx(isEditUser ? "grid grid-cols-[40px_1fr_56px]" : "grid grid-cols-[40px_1fr]", " gap-2 py-2")}>
            <div className={clsx(
                claim.claimType === 'independent-claim' ? "text-pcx-500 font-bold" : "text-slate-400 font-medium",
                "text-right pr-2"
            )}>{claim.claimNumber}.</div>

            <div dangerouslySetInnerHTML={{ __html: claim.claimText ?? "" }}></div>

            {isEditUser &&
                <div className="flex flex-row gap-1 justify-end">
                    <Link to={'' + claim.claimId} className="btn-primary w-6 h-6 p-1"><IconEdit /></Link>
                    <DeleteButton {...{
                        del: () => entityOperation("claim", "delete", claim.claimId),
                        small: true
                    }} />
                </div>}
        </div>
    )
}

export function EditAddClaim() {
    const { t } = useTranslation()
    const { setErrorMessage } = useMessages()

    const navigate = useNavigate()
    const { claimId: _claimId } = useParams()
    const claimId = parseInt(_claimId)

    const { members } = usePatents()
    const { claims, /*members,*/ hasLoaded } = useBackend()
    const originalClaim = claims.find(c => c.claimId === claimId)
    const isAdding = originalClaim === undefined

    const { familyMemberId, addClaim, updateClaim, claimVersion: { version, versionDate }, currentClaims } = useClaims()

    const reservedClaimNumbers = currentClaims
        .filter(c => c.claimNumber !== originalClaim?.claimNumber)
        .map(c => c.claimNumber)

    //console.log(version, versionDate)
    //console.log(hasLoaded)
    //console.log(isAdding)
    //console.log(claims)
    //console.log(originalClaim)

    const internalReference = members.find(m => m.familyMemberId === familyMemberId)?.internalReference ?? ''

    //console.log({originalClaim})
    const initialValues: Claim = !isAdding
        ? originalClaim
        : {
            claimNumber: Math.max(...[0, ...currentClaims.map(c => c.claimNumber)]) + 1,
            claimType: 'independent-claim',
            claimText: '',
            version,
            versionDate,
            familyMemberId,
        }

    const labelStyle = "text-slate-800 w-32 inline-block font-medium pb-2"

    return (hasLoaded
        ? <Modal escAction={() => navigate('../')}>
            <Formik
                initialValues={initialValues}
                onSubmit={(claim) => {
                    //console.log(claim)
                    return (isAdding
                        ? addClaim(claim)
                        : updateClaim(claim))
                        .then(() => navigate(-1))
                        .catch(err => setErrorMessage(err.message))
                }}
                validate={(values) => {
                    const errors = {}
                    if (extractContent(values?.claimText ?? "").trim() === "")
                        errors['claimText'] = t("claim-text-required")
                    const claimNumber = +values.claimNumber
                    if (reservedClaimNumbers.includes(claimNumber))
                        errors['claimNumber'] = t("is-already-used")
                    return errors
                }}
            >{({ errors, touched }) =>
                <Form className="">
                    <div className="flex flex-col gap-4 p-4">
                        <h4>{isAdding
                            ? <Trans i18nKey="add-claim-for-name" values={{ name: internalReference }} />
                            : <Trans i18nKey="edit-claim-for-name" values={{ name: internalReference }} />
                        }</h4>
                        <div className="flex sm:flex-row flex-col gap-4">
                            <label>
                                <span className={labelStyle}>{t('claim-number')}</span> <br />
                                <Field name="claimNumber" className="form-input w-40" />
                                    {touched.claimNumber && errors.claimNumber && <>
                                        <br /> <span className="text-red-700">{errors.claimNumber} </span>
                                    </>}
                            </label>

                            <label><span className={labelStyle}>{t('claim-type')}</span> <br />
                                <Field name="claimType" className="form-select" as="select" >
                                    {['independent-claim', 'dependent-claim'].map(ct =>
                                        <option key={ct} value={ct}>{t(ct)}</option>
                                    )}
                                </Field>
                            </label>
                            </div>
                            <div>
                                <label htmlFor="claimText">
                                    <span className={labelStyle}>{t('claim-text')}</span>
                                    {touched.claimText && errors.claimText && <span className="text-red-700">{errors.claimText} </span>}
                                </label>
                                <div className="sm:h-[16rem] md:h-[24rem] md:min-w-[40rem] max-w-[48rem]">
                                    <Editor name="claimText" />
                                </div>
                            </div>
                    </div>
                    <div className="bg-pc-200 p-4 flex flex-row-reverse gap-2">
                        <input disabled={touched.claimText && (errors.claimText ?? '') !== ''} type="submit" value={t("save")} className="btn-primary" />
                        <Link className="btn-secondary" to="../">{t('cancel')}</Link>
                    </div>
                </Form>
                }</Formik>
        </Modal>
        : null
    )
}

export function EditClaimVersion() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { entityOperation } = useBackend()
    const { claimVersion, currentClaims, setClaimVersion } = useClaims()

    function removeClaimVersion() {
        return Promise.all(currentClaims.map(c => entityOperation("claim", "delete", c.claimId)))
            .then(() => entityOperation("claim", "get"))
            .then(() => setClaimVersion(undefined))
            .then(() => navigate(-1))
    }

    function update(newVersion: { version: string, versionDate: string }) {
        return Promise.all(currentClaims.map(c => entityOperation("claim", "update", { ...c, version: newVersion.version, versionDate: newVersion.versionDate })))
            .then(() => entityOperation("claim", "get"))
            .then(() => setClaimVersion(newVersion))
            .then(() => navigate(-1))
    }


    return (
        <Modal escAction={() => navigate(-1)}>
            <Formik
                initialValues={claimVersion}
                onSubmit={(newVersion) => {
                    //console.log("Submitting...")
                    update(newVersion)
                }}
                validate={(values) => {
                    const errors: ClaimVersionError = {}
                    if ((values.version ?? "").trim() === "")
                        errors.version = "Version must be set"
                    else if ((values.versionDate ?? "").trim() === "")
                        errors.versionDate = "Version Date must be set"
                    return errors
                }}
            >{({ touched, errors, dirty }) =>
                <Form>
                    <div className="flex flex-col p-4 gap-2">
                        <label className="w-full">
                            <div className="label mb-1">{t('version')} <span className="text-red-700">{touched.version && errors.version}</span></div>
                            <Field className="form-input w-full" name="version" />
                        </label>
                        <label className="w-full">
                            <div className="label mb-1">{t('versionDate')} <span className="text-red-700">{touched.versionDate && errors.versionDate}</span></div>
                            <Field className="form-input py-1 w-full" name="versionDate" as={DatePicker} />
                        </label>
                    </div>
                    <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                        <input className="btn-primary" type="submit" value={t("save")} />
                        {/* @ts-ignore */}
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                        <div className="grow" />
                        <input type="button" className="btn-warn disabled:btn-disabled"
                            disabled={dirty}
                            value={t("delete-claim-version")}
                            onClick={removeClaimVersion}
                        />
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

interface ClaimVersionError {
    version?: string;
    versionDate?: string;
}

export interface ClaimVersionProps {
    version: string;
    versionDate: string;
    isCopy: boolean;
    copyFrom: string;
    copyVersion: number;
}

export function AddClaimVersion() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    
    const { internalReference } = useParams()

    const [searchParams] = useSearchParams();
    const copy = searchParams.get("copy") === "true"

    const { setClaimVersion, familyMemberId, familyClaims } = useClaims()

    const { members, memberById, memberByReference } = usePatents()
    //const { members, entityOperation, memberById, memberByReference } = useBackend()
    const { entityOperation } = useBackend()
    const member = memberById[familyMemberId]
    const familyMembers = members.filter(m => m.patentFamilyId === member.patentFamilyId)

    const relevantClaims = familyClaims
    const membersWithClaims = _.uniq(relevantClaims.map(c => c.familyMemberId))

    //console.log(relevantClaims)
    //console.log(membersWithClaims)

    const existingVersionDates = new Set(_(relevantClaims).filter(c => c.familyMemberId === familyMemberId).map(({ versionDate }) => versionDate).uniq().value())
    const existingVersions = new Set(_(relevantClaims).filter(c => c.familyMemberId === familyMemberId).map(({ version }) => version).uniq().value())

    const versionsByRef = _(familyMembers).map(({ internalReference, familyMemberId }) => {
        const memClaims = relevantClaims.filter(c => c.familyMemberId === familyMemberId)
        const versions = _(memClaims)
            .map(({ version, versionDate }) => ({ version, versionDate }))
            .uniqWith(_.isEqual)
            .sortBy(v => v.versionDate)
            .reverse()
            .value()
        return [
            internalReference,
            versions,
        ]
    }).fromPairs().value()

    const refs = _(membersWithClaims).map(id => memberById[id]).sortBy(m => m.internalReference).value()

    function addNewVersion({ version, versionDate, isCopy, copyFrom, copyVersion: copyVersionIndex }) {
        //console.log(copyVersionJson)
        if (!isCopy) {
            setClaimVersion({ version, versionDate })
            navigate("..")
        } else {
            const copyVersion = versionsByRef[copyFrom][copyVersionIndex]
            const copyId = memberByReference[copyFrom]?.familyMemberId
            const newClaims = relevantClaims.filter(c => c.familyMemberId === copyId && c.version === copyVersion.version && c.versionDate === copyVersion.versionDate)
            // For some reason, we cannot chain the operations. It looks like entityOperation keeps getting a handle on the originalCollection before adding anything
            // Tried this: https://medium.com/developer-rants/running-promises-in-a-loop-sequentially-one-by-one-bd803181b283
            // and reducing the promises
            Promise.all(newClaims.map(c => entityOperation("claim", "add", { ...c, version, versionDate, familyMemberId, claimId: undefined })))
                .then(() => entityOperation("claim", "get"))
                .then(() => setClaimVersion({version, versionDate}))
                .then(() => navigate(".."))
        }
    }

    const initialCopyFrom = existingVersions.size > 0 ? internalReference : (refs[0]?.internalReference ?? "")
    const initialValues: ClaimVersionProps = { version: "", versionDate: isoDateString(new Date()), isCopy: copy, copyFrom: initialCopyFrom, copyVersion: 0 }

    return (
        <Modal>
            <Formik
                initialValues={initialValues}
                validate={(values) => {
                    const errors: ClaimVersionError = {}
                    if (values.version === undefined || values.version.trim() === "")
                        errors.version = t("is-required")
                    else if (existingVersions.has(values.version))
                        errors.version = t("is-already-used")
                    else if (values.versionDate === undefined || values.versionDate === "")
                        errors.versionDate = t("is-required")
                    else if (existingVersionDates.has(values.versionDate))
                        errors.versionDate = t("is-already-used")
                    return errors
                }}
                onSubmit={addNewVersion}
            >{({ values, touched, errors }) => {
                const versions = versionsByRef[values.copyFrom] ?? []
                return <Form>
                    <div className="flex flex-col gap-2 p-4 sm:max-w-md">
                        <h4 className="whitespace-no-wrap"><Trans i18nKey="add-new-claim-version-to" values={{ name: internalReference }} /></h4>
                        <label>
                            <div className="label mb-1 w-fit">{t('new-version')}
                                {touched.version && errors.version && <span className="text-red-600 inline normal-case">&nbsp; {errors.version as string} <br /></span>}
                            </div>
                            <Field className="form-input" name="version" autoFocus />
                        </label>

                        <label className="w-fit">
                            <div className="label mb-1">
                                {t('version-date')}
                                {touched.versionDate && errors.versionDate && <span className="text-red-600 inline normal-case">&nbsp; {errors.versionDate as string} <br /></span>}
                            </div>
                            <Field className="form-input py-2" name="versionDate" as={DatePicker} />
                        </label>

                        <div className="flex flex-col gap-2 min-w-fit">
                            {!copy && <label className="w-fit pt-2 flex flex-row items-center gap-2">
                                <Field className="form-checkbox" name="isCopy" type="checkbox" /> <span className="label normal-case">{t('create-copy')}</span>
                            </label>}

                            {values.isCopy && <>
                                <label className="">
                                    <span className="label mb-1">{t('copy-from')}</span>
                                    <Field className="form-select" name="copyFrom" as="select">
                                        {refs.map(r =>
                                            <option
                                                key={r.internalReference}
                                                value={r.internalReference}
                                            >{r.internalReference} ({r.countryCode})</option>)}
                                    </Field>
                                </label>

                                {values.copyFrom !== "" && <label className="">
                                    <span className="label mb-1">{t('copy-version')}</span>
                                    <Field className="form-select max-w-sm truncate" name="copyVersion" as="select">
                                        {_.map(versions, (v, vi) =>
                                            <option
                                                key={v.versionDate + ' ' + v.version}
                                                value={vi}
                                            >{v.version} ({v.versionDate})</option>)
                                        }
                                    </Field>
                                </label>}
                            </>
                            }
                        </div>

                    </div>
                    <div className="flex flex-row-reverse gap-4 p-4 bg-pc-200">
                        <input type="submit" value={t("add")} className="btn-primary" />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            }}</Formik>
        </Modal>
    )
}