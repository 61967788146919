import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import { Link, useNavigate  } from "react-router-dom";
import { isTrademark, searchBrand, toPcTrademark } from "../import/euipo/backend";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { useTrademarkFamily } from "./TrademarkFamily";

export function TmEuipoInput() {
    const {t} = useTranslation()

    const [registrationNumber, setRegistrationNumber] = useState('')
    const [error, setError] = useState(undefined)

    const navigate = useNavigate()

    const family = useTrademarkFamily()
    const familyId = family?.familyId

    async function doImport() {
        // fill up reigstration number with zeros from the left until we have 9 characters
        const rn = '0'.repeat(9 - registrationNumber.length) + registrationNumber
        const response = await searchBrand(rn)
        //console.log({response})
        // Show error when error, else go to add with import state
        if (isTrademark(response)) {
            const pcTrademark = toPcTrademark(response, familyId)
            //console.log({response, pcTrademark})
            navigate({ pathname: '../add', search: `?familyId=${familyId}` }, { state: { prefill: pcTrademark } })
        } else {
            // is error
            console.warn('EUIPO import error: ' + response)
            if ('title' in response)
                setError(response)
            else
                setError({title: 'Error while importing EUIPO trademark'})
        }
    }

    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-4">{t('euipo-import')}</h3>
                <label className="label block mb-2" htmlFor="registrationNumber">{t('registrationNumber')}</label>
                <input
                    id='registrationNumber' className="form-input block mb-6" autoFocus required
                    value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)} />
                {error &&
                    <div className="text-warn-900 max-w-2xs text-xs pl-3 pr-1 py-1 border-l-4 border-warn-500 bg-warn-50">
                        <h4 className="font-semibold text-xs text-warn-900">{error.title}</h4>
                        <p>{error.detail ?? ''}</p> 
                        <a href={`mailto:support@patent-cockpit.com?subject=EIOPA-${encodeURIComponent(error.title + ' -- ' + error.detail)}`}>
                            <EnvelopeIcon className="size-3 inline mb-px mr-0.5"/> <span  className="underline">{t('support')}</span>
                        </a>
                    </div>}
            </div>
            <div className="flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                <button className="btn-primary" onClick={doImport}>{t('import')}</button>
                {/* @ts-ignore */}
                <Link to={-1} className="btn-secondary">{t('cancel')}</Link>
            </div>
        </Modal>
    )

}